import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CardSlider from '../CardSlider/CardSlider';
import { previewsByMainLevelSelector } from 'redux/modules/resources';
import { mapEntityToKeyword } from 'utils/config';
import styles from './HomePreviewsRow.module.scss';

const HomePreviewsRow = ({ title, mainLevel, previews }) => (
  <div className={cx('row', styles.homePreviewsRow)}>
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="cs-section-title left">
        <h2>
          {title} resources
          <NavLink
            to={`/${mapEntityToKeyword('resources')}/${mainLevel}/`}
            className={cx('pull-right', styles.viewAll)}
          >
            View All
          </NavLink>
        </h2>
      </div>
    </div>
    <div className="page-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="row">
        <CardSlider resources={previews} />
      </div>
    </div>
  </div>
);

HomePreviewsRow.propTypes = {
  previews: PropTypes.array.isRequired,
};

export default connect((state, ownProps) => {
  const previews = previewsByMainLevelSelector(ownProps.mainLevel)(
    state,
    ownProps
  );

  return { previews };
})(HomePreviewsRow);
