import React from 'react';
import Slider from 'react-slick';
import Preview from '../Card/Card';

const CardSlider = ({ resources }) => {
  return (
    <Slider
      dots
      arrows={false}
      autoplay={false}
      draggable={false}
      slidesToShow={4}
      slidesToScroll={4}
      responsive={[
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 100000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
      ]}
    >
      {resources?.map(resource => {
        return <Preview key={resource._id} resource={resource} />;
      })}
    </Slider>
  );
};

export default CardSlider;
